<template>
    <div class="nav" :class="[{
        bg:bg,
    },type]">
        <div class="_wrap">
            <div class="logo _link" @click="routerPush('Index')">
                <img src="@/assets/img/logo-a.png" alt="" class="img-a">
                <img src="@/assets/img/logo-b.png" alt="" class="img-b">
            </div>
            <div class="list">
                <div class="item _link" :class="{
                act:$route.name=='Index'
            }" @click="routerPush('Index')">
                    首页
                </div>
                <div class="item _link" :class="{
                act:$route.name=='Programme'||$route.name=='ProgrammeDetails'
            }" @click="routerPush('Programme')">
                    解决方案
                </div>
                <div class="item _link" :class="{
                act:$route.name=='Information'||$route.name=='InformationDetails'
            }" @click="routerPush('Information')">
                    资讯
                </div>
            </div>
            <div class="btn-box">
                <div class="btn _link act" @click="openFun('https://www.tianjingdt.com/')">
                    天境DT数字孪生引擎
                </div>
            </div>
            <!-- <div class="btn-box">
                <div class="btn _link" v-if="!$store.state.token" @click="loginFun">
                    登录
                </div>
                <el-popconfirm v-else title="确定要退出登录吗？" @confirm="logoutFun">
                    <div class="btn _link" slot="reference">{{$store.state.user.account}}
                    </div>
                </el-popconfirm>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {
            type: "type-a",
            bg: false,
        }
    },
    mounted() {
        window.onscroll = this.onScroll
        this.typeTest()
    },
    methods: {
        onScroll() {
            if (window.scrollY > 34) {
                this.bg = true
                return
            }
            this.bg = false
        },
        typeTest() {
            this.type = {
                Index: "type-a",
                ProgrammeDetails: "type-a",
            }[this.$route.name] || ""
        },
        loginFun() {
            this.$bus.$emit("LoginFun")
        },
        logoutFun() {
            this.$store.commit("tokenSet", "")
            this.$store.commit("userSet", {})
            this.$notify({
                title: '退出成功',
            })
        },
    },
    watch: {
        $route() {
            this.typeTest()
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/var.scss";
.nav {
    position: sticky;
    top: 0px;
    background: #fff;
    z-index: 11;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    .list {
        padding: 0 8px;
        line-height: 20px;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
        .item {
            padding: 0 16px;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -ms-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            &:hover,
            &.act {
                color: $main_color;
            }
            &.dt {
                color: $main_color;
                font-weight: bold;
            }
        }
    }
    ._wrap {
        display: flex;
        flex-direction: row;
        height: 68px;
        align-items: center;
        .logo {
            width: 146-8+48px;
            position: relative;
            img {
                -webkit-transition: all .3s ease-in-out;
                -moz-transition: all .3s ease-in-out;
                -ms-transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
            }
            .img-b {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }
        }
        .btn {
            min-width: 60px;
            line-height: 36px;
                background: linear-gradient( 225deg, #40A0FF 0%, #3E68FE 50%, #40A0FF 100%);
            text-align: center;
            font-size: 14px;
            border-radius: 4px;
            /*border: 1px solid $main_color;*/
            color: #fff;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -ms-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            padding: 0 15px
        }
    }
    &.type-a {
        background: rgba(0, 0, 0, 0);
        margin-bottom: -68px;
        color: #fff;
        .logo {
            .img-a {
                opacity: 0;
            }
            .img-b {
                opacity: 1;
            }
        }
        .btn {
            background: none;
            /*border: 1px solid #fff;*/
            &:hover,
            &.act {
                background: linear-gradient( 225deg, #40A0FF 0%, #3E68FE 50%, #40A0FF 100%);
                /*border: 1px solid $main_color;*/
            }
        }
    }
    &.bg {
        background: #fff;
        color: #333;
        .logo {
            .img-a {
                opacity: 1;
            }
            .img-b {
                opacity: 0;
            }
        }
        .btn {
                background: linear-gradient( 225deg, #40A0FF 0%, #3E68FE 50%, #40A0FF 100%);
        }
    }
}
</style>